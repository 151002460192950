<template>
  <div>
    <store-settings-header :titleBarActions="titleBarActions" :hide-env-selector="editing"/>
    <v-container class="align">
      <v-row  class="settings-container">
        <SettingsForm :groups="settingsGroups" :values="values" @change="formChanged" />
      </v-row>
    </v-container>
    <template></template>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import SettingsForm from "../components/storesettings/settings_form.vue";
import storeSettingsHeader from "../components/store_settings_header.vue";
import StoreSettingsMixin from "../mixins/store_settings.js";
import clone from "clone";

export default {
    name: "StoreSettingsPOS",
    mixins: [StoreSettingsMixin],
    components: {
        storeSettingsHeader,
        SettingsForm
    },
    data() {
        return {
            editing: false,
            values: {}
        };
    },
    computed: {
        titleBarActions() {
            if (!this.editing) {
                return [
                    {
                        type: "edit",
                        display: "Edit",
                        run: () => {
                            this.editing = true;
                        }
                    }
                ];
            } else {
                return [
                    {
                        type: "cancel",
                        display: "Cancel",
                        run: () => {
                            this.editing = false;
                            this.values = clone(this.tabletSettings);
                        }
                    },
                    {
                        type: "proceed-green",
                        display: "Save",
                        run: () => {
                            this.saveSettings();
                        }
                    }
                ];
            }
        },
        settingsGroups() {
            let posGroup = {
                name: "Point of Sale",
                sections: [
                    {
                        fields: [
                            {
                                key: "integrated",
                                display: "Integration",
                                type: "choice",
                                choices: ["Integrated", "Non-Integrated"],
                                disabled: true
                            },
                            {
                                key: "version",
                                display: "Version",
                                type: "singleline",
                                disabled: true
                            }
                        ]
                    }
                ]
            };
            let tabletGroup = {
                name: "Grab Tablet Settings",
                sections: [
                    {
                        disabled: !this.editing,
                        fields: [
                            {
                                key: "TabletAlertsEnabled",
                                display: "Enable Tablet Alerts",
                                type: "boolean"
                            },
                            {
                                key: "TabletTablesEnabled",
                                display: "Enable Tables and Sections Feature",
                                type: "boolean"
                            },
                            {
                                key: "TabletTableAssignmentAlertEnabled",
                                display: "Enable Unassigned Tables Alert",
                                type: "boolean",
                                disabled:
                  this.values.TabletAlertsEnabled !== true ||
                  this.values.TabletTablesEnabled !== true
                            },
                            {
                                key: "TabletOpenTicketsEnabled",
                                display: "Enable Open Tickets Feature",
                                type: "boolean"
                            },
                            {
                                key: "TabletOpenTicketsAlertEnabled",
                                display: "Enable Open Ticket Alerts",
                                type: "boolean",
                                disabled:
                  this.values.TabletAlertsEnabled !== true ||
                  this.values.TabletOpenTicketsEnabled !== true
                            },
                            {
                                key: "TabletAllowCancel",
                                display: "Allow items to be edited or removed on tablet",
                                type: "boolean"
                            }
                        ]
                    }
                ]
            };

            if (this.tabletSettings) {
                return [posGroup, tabletGroup];
            }
            return [posGroup];
        },
        integrated() {
            if (!Object.keys(this.store).length) {
                return false;
            }

            if (!this.pos || this.pos === "grab") {
                return false;
            }

            return true;
        },

        version() {
            if (!Object.keys(this.store).length) {
                return "";
            }

            switch (this.pos) {
            case "vvn":
                return "Vivonet";
            case "omnivore":
                return "Omnivore";
            default:
                return "Grab";
            }
        },
        tabletSettings() {
            if (this.env == "stage") return this.tabletSettingsStage;
            if (this.env == "prod") return this.tabletSettingsProd;
        },
        ...mapGetters("SettingsStore", [
            "tabletSettingsStage",
            "tabletSettingsProd"
        ]),
        ...mapGetters(["store", "pos"])
    },
    methods: {
        formChanged(newValue) {
            var changes = {};

            if (newValue.tabletAlertsEnabled == false) {
                changes.tabletTablesAlertEnabled = false;
                changes.tabletOpenTicketAlertEnabled = false;
            }
            if (newValue.tabletOpenTicketEnabled == false) {
                changes.tabletOpenTicketAlertEnabled = false;
            }
            if (newValue.tabletTablesEnabled == false) {
                changes.tabletTablesAlertEnabled = false;
            }

            this.debounceValuesChange(changes);
        },
        resolvePageTitle() {
            if (Object.keys(this.store).length) {
                document.title = `${this.store.storeName} - Pos`;
            }
        },
        saveSettings() {
            let storeWaypointID = this.$route.params.store;
            let env = this.env;

            this.saveTabletSettings({
                storeWaypointID,
                env,
                settings: clone(this.values)
            }).then(() => {
                this.values = clone(this.tabletSettings);
                this.values.version = this.version;
                this.values.integrated = this.integrated
                    ? "Integrated"
                    : "Non-Integrated";
                this.editing = false;
            });
        },
        loadData() {
            this.getTabletSettings({
                storeWaypointID: this.$route.params.store,
                env: this.environment,
            }).then(() => {
                this.values = clone(this.tabletSettings);
                this.values.version = this.version;
                this.values.integrated = this.integrated
                    ? "Integrated"
                    : "Non-Integrated";
            });
        },
        ...mapActions("SettingsStore", ["getTabletSettings", "saveTabletSettings"])
    },
    watch: {
        store() {
            this.resolvePageTitle();
        },
        env() {
            this.loadData();
        }
    },
    created() {
        this.loadData();
    }
};
</script>

<style lang="scss" scoped>
.settings-container {
  max-width: 75%;
}
</style>
