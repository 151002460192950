var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("store-settings-header", {
        attrs: {
          titleBarActions: _vm.titleBarActions,
          "hide-env-selector": _vm.editing,
        },
      }),
      _c(
        "v-container",
        { staticClass: "align" },
        [
          _c(
            "v-row",
            { staticClass: "settings-container" },
            [
              _c("SettingsForm", {
                attrs: { groups: _vm.settingsGroups, values: _vm.values },
                on: { change: _vm.formChanged },
              }),
            ],
            1
          ),
        ],
        1
      ),
      void 0,
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }